<template>
    <p v-html="template"></p>
</template>

<script>

export default {
    data:() => ({
        fields: {},
        template: ''
    }),
    props: {
        claim: {
            type: Object,
            default: {}
        },
        preview: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.$emit('templateFields', this.getFields())
    },
    methods: {
        getFields() {
            return this.fields
        }
    }
}
</script>